import(/* webpackMode: "eager" */ "/usr/src/tmp/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/tmp/src/modules/welcome/welcome.webp");
;
import(/* webpackMode: "eager" */ "/usr/src/tmp/src/ui/logo/logo.module.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/tmp/src/ui/withBoxModel/withBoxModel.module.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/tmp/src/ui/text/text.module.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/tmp/src/modules/welcome/country-list/country-list.module.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/tmp/src/modules/welcome/welcome.module.scss");
